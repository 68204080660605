import {
  AccordionItem,
  CultureType,
  KeyFigureSection,
} from "@sop/ui-library";
import { useT } from "../../../shared/internationalization";
import { KeyFigures } from "./WealthSection.interface";

export default function WealthAccordion({
  culture,
  loading,
  keyFigures,
}: {
  culture: CultureType;
  loading: boolean;
  keyFigures: KeyFigures;
}) {
  const phrases = useT("WealthSection");

  return (
    <AccordionItem theme="white" slim styleVariant="standard">
      <div>
        <KeyFigureSection
          anchorName="keyfigure-anchor-header"
          display="flex"
          culture={culture}
          keyFigures={[
            {
              loading: loading,
              keyFigure: keyFigures?.marketValue,
              prefix: culture === "en-US" ? `${phrases("SEK")} ` : undefined,
              suffix: culture === "sv-SE" ? ` ${phrases("SEK")}` : undefined,
              text: phrases("MarketValue"),
              animated: true,
              culture: culture,
            },
            {
              loading: loading,
              keyFigure: keyFigures?.development,
              suffix: "%",
              text: phrases("Development"),
              animated: true,
              culture: culture,
            },
          ]}
          identifier="751cc379-eb67-4132-9bb0-01ea55bdb240"
        />
      </div>
      <div>
        <KeyFigureSection
          anchorName="keyfigure-anchor"
          culture={culture}
          keyFigures={[
            {
              keyFigure: keyFigures?.developmentSinceStart,
              prefix: culture === "en-US" ? `${phrases("SEK")} ` : undefined,
              suffix: culture === "sv-SE" ? ` ${phrases("SEK")}` : undefined,
              text: phrases("DevelopmentSinceStart"),
              percentage: keyFigures?.developmentSinceStartPercentage,
              animated: false,
              culture: culture,
            },
            {
              keyFigure: keyFigures?.availableFunds,
              prefix: culture === "en-US" ? `${phrases("SEK")} ` : undefined,
              suffix: culture === "sv-SE" ? ` ${phrases("SEK")}` : undefined,
              text: phrases("LiquidFunds"),
              animated: false,
              culture: culture,
            },
            {
              keyFigure: keyFigures?.dividends,
              prefix: culture === "en-US" ? `${phrases("SEK")} ` : undefined,
              suffix: culture === "sv-SE" ? ` ${phrases("SEK")}` : undefined,
              text: phrases("Dividends"),
              animated: false,
              culture: culture,
            },
            {
              keyFigure: keyFigures?.deposits,
              prefix: culture === "en-US" ? `${phrases("SEK")} ` : undefined,
              suffix: culture === "sv-SE" ? ` ${phrases("SEK")}` : undefined,
              text: phrases("Deposits"),
              animated: false,
              culture: culture,
            },
          ]}
          identifier="751cc379-eb67-4132-9bb0-01ea55bdb240"
        />
      </div>
    </AccordionItem>
  );
}
