import { useState, useEffect, useContext } from "react";
import { BodyM, ColumnChart, LabelM } from "@sop/ui-library";
import { StyledContainer } from "../Common/Container.styled";
import { StyledCallToAction } from "../Common/CallToAction.styled";
import { useT } from "../../../shared/internationalization";
import useIsMobile, { redirectUrls } from "../../../shared/utils";
import { GraphContainer, GraphSection } from "./PensionPrognosisGraph.styled";
import { CultureContext } from "@sop/unify-ui-react";
import {
  CustomOptions,
  PensionPrognosisGraphData,
} from "./PensionPrognosisGraph.interface";
import { getPensionPrognosisGraph } from "../../../shared/services/spPlusService";
import Snackbar, { SnackBarState } from "../Snackbar";

export default function PensionPrognosisGraph() {
  const phrases = useT("PensionPrognosisGraphSection");
  const culture = useContext(CultureContext).culture as "sv-SE" | "en-US";
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(true);
  const [customOptions, setCustomOptions] = useState<CustomOptions>(
    {} as CustomOptions
  );
  const [data, setData] = useState<PensionPrognosisGraphData>(
    {} as PensionPrognosisGraphData
  );

  const [snackbarState, setSnackbarState] = useState<SnackBarState>({
    open: false,
    severity: "info",
    message: "",
    alertTitle: "",
  });

  const { alertTitle, message, open, severity } = snackbarState;

  const handleCloseSnackbar = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState({ ...snackbarState, open: false });
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      try {
        const response = await getPensionPrognosisGraph();

        if (!response.success) {
          setSnackbarState({
            open: true,
            severity: "error",
            message: phrases("SnackbarErrorMessage"),
            alertTitle: phrases("SnackbarErrorTitle"),
          });
          setData({
            chart: {},
            currentSalary: 0,
            localizedCurrentSalary: "",
          } as PensionPrognosisGraphData);
          setCustomOptions({
            xAxis: {
              categories: [],
            },
            series: [
              {
                type: "column",
                name: phrases("PublicPensionsLabel"),
                data: [],
              },
              {
                type: "column",
                name: phrases("OccupationalPensionsLabel"),
                data: [],
              },
              {
                type: "column",
                name: phrases("PrivateInsuranceLabel"),
                data: [],
              },
            ],
          });
        } else {
          setData(response.data);
          setCustomOptions({
            xAxis: {
              categories: response.data.chart.ageLabels.map((label) => {
                return `${label} ${phrases("YearLabel").toLowerCase()}`;
              }),
            },
            series: [
              {
                type: "column",
                name: phrases("PublicPensionsLabel"),
                data: response.data.chart.publicPensions,
              },
              {
                type: "column",
                name: phrases("OccupationalPensionsLabel"),
                data: response.data.chart.occupationalPensions,
              },
              {
                type: "column",
                name: phrases("PrivateInsuranceLabel"),
                data: response.data.chart.privateInsurance,
              },
            ],
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setSnackbarState({
          open: true,
          severity: "error",
          message: phrases("SnackbarErrorMessage"),
          alertTitle: phrases("SnackbarErrorTitle"),
        });
      }
    }
    fetchData().then(() => setLoading(false));
  }, [phrases]);

  return (
    <>
      <StyledContainer theme="lightgray">
        <Snackbar
          open={open}
          severity={severity}
          message={message}
          alertTitle={alertTitle}
          handleCloseSnackbar={handleCloseSnackbar}
        />
        <StyledCallToAction
          contentTheme="black"
          heading={phrases("PensionPrognosisGraphHeading")}
          linkText=""
          text={!isMobile ? phrases("PensionPrognosisGraphDescription") : ""}
          url={redirectUrls.pensionBaseUrl}
        />

        <GraphSection theme="white">
          <LabelM>{phrases("GraphSectionTitle")}</LabelM>

          {!loading && (
            <BodyM>
              {phrases("GraphSalaryLabel")} {data.localizedCurrentSalary}{" "}
              {phrases("GraphCurrencyLabel")}
            </BodyM>
          )}

          <GraphContainer>
            <ColumnChart
              loading={loading}
              culture={culture}
              customOptions={customOptions}
              plotlineValue={data.currentSalary}
              plotlineLabel={phrases("PlotlineLabel")}
              footerTotalText={phrases("GraphTotalLabel")}
              suffix={phrases("SwedishCurrencyShort")}
              plotlineSeriesName={phrases("GraphTodayLabel")}
            />
          </GraphContainer>
        </GraphSection>
      </StyledContainer>
    </>
  );
}
