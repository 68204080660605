import {
  HeadingM,
  BodyS,
  BodyM,
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
} from "@sop/ui-library";
import React, { useState } from "react";
import BankIdDialog from "../BankIdDialog";
import {
  getAgreementText,
  initiateCreateAccount,
} from "../../../shared/services/spPlusService";
import {
  AccountStatus,
  InitiateCreateAccountResult,
} from "../BankIdDialog/BankIdDialog.interface";
import { useT } from "../../../shared/internationalization";
import { useQuery } from "@tanstack/react-query";
import DOMPurify from "dompurify";
import { ROUTES } from "../../../shared/router/router.constants";
import { useNavigate } from "react-router-dom";

export default function SignUpDialog({
  showOnLoad = false,
}: {
  showOnLoad?: boolean;
}) {
  const phrases = useT("SpPlus");
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(showOnLoad);
  const [signButtonEnabled, setSignButtonEnabled] = React.useState(false);
  const [openBankIdDialog, setOpenBankIdDialog] = React.useState(false);
  const [signingResult, setSigningResult] = useState<
    InitiateCreateAccountResult | undefined
  >(undefined);

  const { data, isPending } = useQuery({
    queryKey: ["getAgreementText"],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const result = await getAgreementText();
      return result;
    },
    enabled: open,
  });

  const handleScroll = (e: any) => {
    const threshold = 1;
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      ) <= threshold;
    if (bottom) {
      setSignButtonEnabled(true);
    } else {
      setSignButtonEnabled(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSignButtonEnabled(false);
    setOpen(false);
  };

  const handleStartSigning = async () => {
    const result = await initiateCreateAccount();

    if (!result.success) {
      navigate(ROUTES.ERROR, { replace: true });
      return;
    }

    if (result.accountStatus == AccountStatus.AlreadyExists) {
      navigate(ROUTES.PLUS, { replace: true });
      return;
    }

    setSigningResult(result);
    setOpenBankIdDialog(true);
  };

  const handleCloseBankIdDialog = () => {
    setOpenBankIdDialog(false);
  };

  const dataSanitized = DOMPurify.sanitize(data ?? "", {
    ADD_ATTR: ["target"],
  });

  return (
    <>
      <Button
        actionTheme="coral"
        onClick={handleClickOpen}
        size="medium"
        text={phrases("SpPlusBannerButtonText")}
        variant="primary"
        style={{ width: "100%" }}
        isLink
      ></Button>
      {!isPending && (
        <Dialog open={open}>
          <DialogTitle>
            <HeadingM>{phrases("SignUpDialogHeader")}</HeadingM>
            <Button
              actionTheme="black"
              icon="close28"
              iconOnly
              onClick={handleClose}
              size="small"
              style={{
                position: "absolute",
                right: "1rem",
                top: "1rem",
              }}
              variant="outline"
            />
          </DialogTitle>
          <DialogContent style={{ display: "grid", gap: "1rem" }}>
            <BodyS>
              <strong>{phrases("SignUpDialogDescription")}</strong>
            </BodyS>
            <div
              style={{ maxHeight: 300, overflowY: "scroll" }}
              onScroll={handleScroll}
            >
              <BodyM
                style={{ whiteSpace: "pre-wrap" }}
                dangerouslySetInnerHTML={{ __html: dataSanitized }}
              ></BodyM>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              actionTheme="blue"
              linkText={phrases("SignUpDialogDiscardButtonText")}
              onClick={handleClose}
              size="medium"
              variant="outline"
            />
            <Button
              actionTheme="blue"
              linkText={phrases("SignUpDialogBankIdButtonText")}
              onClick={handleStartSigning}
              size="medium"
              variant="primary"
              disabled={!signButtonEnabled}
              icon="bankId28"
            />
          </DialogActions>
        </Dialog>
      )}
      {signingResult && (
        <BankIdDialog
          signingResult={signingResult}
          isOpen={openBankIdDialog}
          handleClose={handleCloseBankIdDialog}
        />
      )}
    </>
  );
}
